<template>
  <div class="dashboard">
    
    <b-row>
      <b-col class="content-header-left mb-0" cols="12" md="10">
        <h3 class="float-left pr-1 mb-0"><feather-icon icon="GridIcon" size="1.1x" /> Điều hành dự án CNTT</h3>
      </b-col>
      <b-col class="content-header-right text-md-right d-md-block d-none mb-1" md="2" cols="12">
        <b-input-group>
          <b-input-group-prepend is-text>
            <feather-icon icon="CalendarIcon" />
          </b-input-group-prepend>
          <flat-pickr v-model="current_date" class="form-control flat-pickr" :config="{altInput: true,dateFormat: 'm/Y',altFormat: 'm/Y'}"/>
        </b-input-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="6">
        <div class="card">
					<div class="card-head">
						<span class="title">Dự án cấp tỉnh</span>      
					</div>
					<div class="card-body p-0">
						<vue-apex-charts type="bar" height="350" :options="du_an_cap_tinh.chartOptions" :series="du_an_cap_tinh.series"/>
					</div>
				</div>
      </b-col>
      <b-col cols="6">
        <div class="card">
					<div class="card-head">
						<span class="title">Dự án cấp trung ương</span>      
					</div>
					<div class="card-body p-0">
						<vue-apex-charts type="bar" height="350" :options="du_an_cap_tw.chartOptions" :series="du_an_cap_tw.series"/>
					</div>
				</div>
      </b-col>
    </b-row>
    <h4>Dự án theo từng tỉnh</h4>
    <b-row>
      <b-col cols="4">
        <div class="card">
					<div class="card-head">
            <div class="select-box">
              <Select2 v-model="current_province.id" :options="provinces"/>
						</div>
            <div class="select-box" style="padding-right:5px">
              <Select2 v-model="mapConfig.headmap_value_key" :options="[{id:'TONGDOANHSO',text:'Theo Doanh số'},{id:'TONGDOANHTHU',text:'Theo Doanh thu'},{id:'TONGDUAN',text:'Theo Số lượng dự án'}]"/>
            </div>
					</div>
					<div class="card-body p-0">
            <div id="vnmap" style="height:700px"></div>
            <div class="card p-0 m-0 border-light" style="width:200px;position:absolute;top:42px;right:5px" v-if="current_province.show">
              <div class="card-head" style="background:{fill}">
                <span class="title">{{current_province.TEN_TINH}}</span>
                <button type="button" class="close" @click="current_province.show=false;current_province.id=''">
                  <feather-icon icon="XIcon" />
                </button>
              </div>
              <div class="card-body p-1">
                <div class="row"><div class="col-6">Số dự án</div><div class="col-6">{{current_province.PJ_AGENT_CNT}}</div></div>
                <div class="row"><div class="col-6">Doanh thu</div><div class="col-6">{{ nf(current_province.PJ_AGENT_VALUE) }} tỉ</div></div>
                <div class="row"><div class="col-6">Doanh số</div><div class="col-6">{{ nf(current_province.PJ_AGENT_RVN) }} tỉ</div></div>
                <div class="row"><div class="col-12 pt-1 text-primary" @click="showProjects(current_province.AGENT_ID)"><a>Xem chi tiết</a></div></div>
              </div>
            </div>
            <div style="position:absolute;top:300px;left:20px" v-if="mapConfig.range!=null && mapConfig.range.length==5">
              <div class="row">
                <div class="col-12" v-for="i in mapConfig.range" :key="i.color">
                  <feather-icon icon="MapPinIcon" :fill="i.color"/> {{i.text}}
                </div>
              </div>
            </div>
					</div>
				</div>
      </b-col>
      <b-col cols="4">
        <div class="card">
          <div class="card-head">
            <span class="title">Chi tiết</span>
          </div>
          <div class="card-body p-0" style="height:700px">
            <vue-good-table max-height="700px" styleClass="vgt-table striped" :line-numbers="true" :columns="da_thuc_hien_detail.columns" :rows="provinces">
            <template slot="table-row" slot-scope="props">
              <span v-if="props.column.field === 'P'">
                <b-progress v-if="props.row.P>80" :value="props.row.P" max="100" show-value variant="info" class="progress-bar-info ml-0 mr-1"/>
                <b-progress v-else-if="props.row.P>50" :value="props.row.P" max="100" show-value variant="warning" class="progress-bar-warning ml-0 mr-1"/>
                <b-progress v-else :value="props.row.P" max="100" show-value variant="danger" class="progress-bar-danger ml-0 mr-1"/>
              </span>
              <span v-else @click="showProjects(props.row.AGENT_ID)">
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>
          </vue-good-table>
          </div>
        </div>
      </b-col>
      <b-col cols="4">
        <div class="card">
          <div class="card-head">
            <span class="title">Tỷ trọng kênh theo sản lượng</span>
          </div>
          <div class="card-body p-0">
            <vue-apex-charts class="pb-1" type="donut" height="260" :options="ti_trong_kenh.chartOptions" :series="ti_trong_kenh.series"/>
          </div>
        </div>
        <h4>Doanh thu theo quý (Triệu VNĐ)</h4>
        <b-row v-if="du_an_theo_quy!=null">
          <b-col cols="6" v-for="i in [{n:'I',v:1},{n:'II',v:2},{n:'III',v:3},{n:'IV',v:4}]" :key="i.n">
            <div class="card">
              <div class="card-body p-1 period-box">
                <h4>Quý {{i.n}}</h4>
                <b-row class="pt-1">
                  <b-col cols="6"><h3 class="mb-0 text-warning">{{du_an_theo_quy.filter(i=>i.NAME==="DOANH_THU")[0]["Q"+i.v] | numFormat}}</h3><small>Doanh thu</small></b-col>
                  <b-col cols="6"><h3 class="mb-0 text-info">{{du_an_theo_quy.filter(i=>i.NAME==="DOANH_SO")[0]["Q"+i.v] | numFormat}}</h3><small>Doanh Số</small></b-col>
                </b-row>
              </div>
            </div>
          </b-col>
          
          <b-col cols="12">
            <div class="card">
              <div class="card-body p-0">
                <vue-apex-charts type="bar" height="162" :options="du_an_theo_quy_bar.chartOptions" :series="du_an_theo_quy_bar.series"/>
              </div>
            </div>
          </b-col>
        </b-row>
        
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="6">
        <div class="card">
          <div class="card-head">
            <span class="title">Dự án theo từng sản phẩm</span>
            <div class="select-box">
              <Select2 v-model="mapConfig.circle_packed_key" :options="[{id:'PJ_CATEGORIE_VALUE',text:'Theo Doanh số'},{id:'PROJECT_CNT',text:'Theo Số lượng dự án'}]"/>
            </div>
          </div>
          <div class="card-body p-0" id="da_theo_spdv" style="height:350px">
            
          </div>
        </div>
      </b-col>
      <b-col cols="6">
        <div class="card">
          <div class="card-head">
            <span class="title">Dự án theo từng nhóm khách hàng</span>
            <div class="select-box">
              <Select2 v-model="mapConfig.tree_map_key" :options="[{id:'DOANHSO',text:'Theo Doanh số'},{id:'SODUAN',text:'Theo Số lượng dự án'}]"/>
            </div>
          </div>
          <div class="card-body p-0" id="da_theo_kh" style="height:350px;border-radius: 0px 0px 5px 5px;overflow:hidden">
            
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="3">
        <div class="card">
          <div class="card-head">
            <span class="title">Tỉ lệ dự án vướng mắc</span>
          </div>
          <div class="card-body text-center" style="height:300px">
            <h1 v-if="du_an_vuong_mac!=null && du_an_vuong_mac.series!=null && du_an_vuong_mac.series.length>1">{{du_an_vuong_mac.series[0]}}/{{du_an_vuong_mac.series[1]}}<small class="pl-1">dự án vướng mắc</small></h1>
            <vue-apex-charts class="pb-1" type="donut" height="260" :options="du_an_vuong_mac.chartOptions" :series="du_an_vuong_mac.series"/>
          </div>
        </div>
      </b-col>
      <b-col cols="9">
        <div class="card">
          <div class="card-head">
            <span class="title">Dự án vướng mắc</span>
          </div>
          <div class="card-body" style="height:300px;overflow:auto;">
            <div class="row" v-for="i in list_du_an_vuong_mac" :key="i.PJ_ID">
              <div class="col-12">
                <h4>{{i.PJ_CODE}}</h4>
                <p>Vướng mắc tại bước: {{i.PROCESS_NAME}}</p>
              </div>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-modal id="modal-detail" title="Danh sách dự án" size="xl" ok-only ok-title="Đóng">
      <b-card-text>
        <vue-good-table max-height="500px" styleClass="vgt-table striped" :line-numbers="true" :columns="list_detail.columns" :rows="list_detail.rows">
            <template slot="table-row" slot-scope="props">
              <span v-if="props.column.field === 'DOANH_THU' || props.column.field === 'DOANH_SO'">
                {{ nf(props.row[props.column.field]) }}
              </span>
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>
          </vue-good-table>
      </b-card-text>
    </b-modal>
    <b-modal id="modal-province-detail" title="Danh sách dự án theo tỉnh" size="lg" ok-only ok-title="Đóng">
      <b-card-text>
        <vue-good-table max-height="500px" styleClass="vgt-table striped" :line-numbers="true" :columns="list_detail.province_columns" :rows="list_detail.rows">
            <template slot="table-row" slot-scope="props">
              <span v-if="props.column.field === 'DOANH_THU' || props.column.field === 'DOANH_SO'">
                {{ nf(props.row[props.column.field]) }}
              </span>
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>
          </vue-good-table>
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import { BModal, BPopover, BRow, BCol,BCard, BTab, BCardText,BTabs,BInputGroupAppend,BInputGroup,BInputGroupPrepend,BFormInput,BProgress } from "bootstrap-vue";
import VuePdfApp from 'vue-pdf-app'
import 'vue-pdf-app/dist/icons/main.css'
import useJwt from "@/auth/jwt/useJwt";
import Select2 from 'v-select2-component'
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue';
import store from '@/store'
import vnMap from '@core/maps/vietnam.geo.json'
import { VueGoodTable } from 'vue-good-table'
import '@core/scss/vue/libs/vue-good-table.scss';
import VueApexCharts from 'vue-apexcharts'
import * as am4core from "@amcharts/amcharts4/core";
import * as am4maps from "@amcharts/amcharts4/maps";
import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4plugins_forceDirected from "@amcharts/amcharts4/plugins/forceDirected";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
am4core.useTheme(am4themes_animated);
import numeral from 'numeral';
import Vue from 'vue';
import numFormat from 'vue-filter-number-format';
Vue.filter('numFormat', numFormat(numeral));
import moment from 'moment'
import Button from '../components/button/Button.vue';

export default {
  components: {BModal,BPopover,VueApexCharts,BRow,BCol,VuePdfApp,Select2,BCard,flatPickr,BTab, BCardText, BTabs, FeatherIcon,BInputGroupAppend,BInputGroup,BInputGroupPrepend,BFormInput,VueGoodTable,BProgress, Button},
  data() {
    return {
      current_province:{show:false},
      provinces:[],
      isDark: store.state.appConfig.layout.skin=='dark',
      report: {TABS:[]},
      tab: null,
      tabIndex: 0,
      vietnam: vnMap,
      listOfParamNameValues:[],
      current_date: '09/2021'/*moment(new Date()).format("MM/YYYY")*/,
      list_detail:{
        columns:[{
            label: 'Tên dự án',
            field: 'PJ_NAME',
            filterOptions: {enabled: true},
          },
          {
            label: 'Chủ trì',
            field: 'DONVI_CHU_TRI',
            tdClass: 'text-left',
            thClass: 'text-left',
            filterOptions: {enabled: true},
          },
          {
            label: 'Milestone',
            field: 'PJ_MILESTONE_NAME',
            thClass: 'text-left',
            filterOptions: {enabled: true},
          },
          {
            label: 'Doanh thu',
            field: 'DOANH_THU',
            tdClass: 'text-right',
            type: 'number',
            thClass: 'text-left',
            filterOptions: {enabled: true},
          },
          {
            label: 'Doanh số',
            field: 'DOANH_SO',
            tdClass: 'text-right',
            type: 'number',
            thClass: 'text-left',
            filterOptions: {enabled: true},
          },
        ],
        province_columns:[{
            label: 'Tên tỉnh',
            field: 'TEN_TINH',
            filterOptions: {enabled: true},
          },
          {
            label: 'Số lượng dự án',
            field: 'TONGDUAN',
            tdClass: 'text-left',
            thClass: 'text-left',
            filterOptions: {enabled: true},
          },
          {
            label: 'Doanh thu',
            field: 'TONGDOANHTHU',
            tdClass: 'text-right',
            type: 'number',
            thClass: 'text-left',
            filterOptions: {enabled: true},
          },
          {
            label: 'Doanh số',
            field: 'TONGDOANHSO',
            tdClass: 'text-right',
            type: 'number',
            thClass: 'text-left',
            filterOptions: {enabled: true},
          },
        ],
        rows:[]
      },
      du_an_cap_tinh: {
        series: [],
        chartOptions: {
          chart: {
            toolbar: {
              show: false,
            },
            events: {
              click: (event, chartContext, config) => {
                  //console.log(config.config.series[config.seriesIndex])
                  //console.log(config.config.series[config.seriesIndex].name)
                  //console.log(config.config.series[config.seriesIndex].data[config.dataPointIndex])
                  useJwt.post('projectMgmt', {
                    service:"func_get_pj_month_by_vnpt_center_detail_service",
                    type:"ref",
                    p1: config.config.series[config.seriesIndex].name,
                    p3: (config.dataPointIndex+1),
                  }).then(response =>{
                    this.list_detail.rows=response.data.result;
                    this.$bvModal.show('modal-detail')
                  })
              }
            },
          },
          colors: ['#28c76f','#ea5455'],
          plotOptions: {
            bar: {
              horizontal: true,
              endingShape: 'rounded',
              dataLabels: {
                position: 'top',
              },
            },
          },
          grid: {
            xaxis: {
              lines: {
                show: false,
              },
            },
          },
          dataLabels: {
            enabled: true,
            offsetX: -6,
            style: {
              fontSize: '12px',
              colors: ['#fff','#fff']
            }
          },
          xaxis: {
            categories: ['GĐ Chủ trương','GĐ Lập dự án','GĐ Đấu thầu','GĐ Triển khai','GĐ Nghiệm thu'],
          },
          yaxis: {
            // opposite: isRtl,
          },
        },
      },
      du_an_cap_tw: {
        series: [],
        chartOptions: {
          chart: {
            toolbar: {
              show: false,
            },
            events: {
              click: (event, chartContext, config) => {
                  useJwt.post('projectMgmt', {
                    service:"func_get_pj_month_by_vnpt_prov_detail_service",
                    type:"ref",
                    p1: config.config.series[config.seriesIndex].name,
                    p3: (config.dataPointIndex+1),
                  }).then(response =>{
                    this.list_detail.rows=response.data.result;
                    this.$bvModal.show('modal-detail')
                  })
              }
            },
          },
          colors: ['#00B0EC','#ff9f43'],
          plotOptions: {
            bar: {
              horizontal: true,
              endingShape: 'rounded',
              dataLabels: {
                position: 'top',
              },
            },
          },
          grid: {
            xaxis: {
              lines: {
                show: false,
              },
            },
          },
          dataLabels: {
            enabled: true,
            offsetX: -6,
            style: {
              fontSize: '12px',
              colors: ['#fff','#fff']
            }
          },
          xaxis: {
            categories: ['GĐ Chủ trương','GĐ Lập dự án','GĐ Đấu thầu','GĐ Triển khai','GĐ Nghiệm thu'],
          },
          yaxis: {
            // opposite: isRtl,
          },
        },
      },
      da_thuc_hien_detail: {
        columns: [
          {
            label: 'Tỉnh',
            field: 'text',
            filterOptions: {enabled: true,placeholder: 'MA_TINH'},
            width: '150px',
          },
          {
            label: 'SL.DA',
            field: 'PJ_AGENT_CNT',
            tdClass: 'text-right',
            type: 'number',
            thClass: 'text-left',
          },
          {
            label: 'Doanh số',
            field: 'PJ_AGENT_VALUE',
            tdClass: 'text-right',
            type: 'number',
            thClass: 'text-left',
          },
          {
            label: 'D.Thu/D.Số',
            field: 'P',
            tdClass: 'text-right',
            type: 'number',
            thClass: 'text-left',
          },
        ]
      },
      du_an_theo_quy:null,
      ti_trong_kenh:{
        series: [],
        chartOptions: {
          labels: ['Chưa có doanh số','Dưới 10 tỉ','10 đến 30 tỉ','30 đến 50 tỉ','Trên 50 tỉ'],
          legend: {
            show: true,
            position: 'bottom',
            fontSize: '14px',
            fontFamily: 'Montserrat',
          },
          colors: [
            '#00B0EC',
            '#7367f0',
            '#ff9f43',
          ],
          dataLabels: {
            enabled: true,
            formatter(val) {
              return `${parseInt(val)}%`
            },
          },
          chart: {
            events: {
              /*List dự án theo tỉ trọng*/
              dataPointSelection: (event, chartContext, config) => {
                  console.log(config.dataPointIndex)
                  console.log(config)
                  useJwt.post('projectMgmt', {
                    service:"func_get_list_pj_product_detail_by_vnpt_service",
                    type:"ref",
                    p1: this.current_date.substr(3)+this.current_date.substring(0,2),
                    p3: (config.dataPointIndex+1),
                  }).then(response =>{
                    this.list_detail.rows=response.data.result;
                    this.$bvModal.show('modal-province-detail')
                  })
              }
            },
          },
        }
      },
      du_an_theo_quy_bar: {
        series: [],
        chartOptions: {
          chart: {
            toolbar: {
              show: false,
            },
          },
          colors: ['#28c76f','#ea5455'],
          plotOptions: {
            bar: {
              horizontal: false,
              dataLabels: {
                position: 'top',
              },
            },
          },
          grid: {
            xaxis: {
              lines: {
                show: false,
              },
            },
          },
          dataLabels: {
            enabled: true,
            style: {
              fontSize: '12px',
              colors: ['#fff','#fff']
            }
          },
          xaxis: {
            categories: ['Q1','Q2','Q3','D4'],
          },
          yaxis: {
            // opposite: isRtl,
          },
        },
      },
      du_an_theo_sp:[],
      du_an_theo_nhom_kh:[],
      du_an_vuong_mac:{
        series: [],
        chartOptions: {
          labels: [],
          legend: {
            show: true,
            position: 'bottom',
            fontSize: '14px',
            fontFamily: 'Montserrat',
          },
          colors: [
            '#00B0EC',
            '#7367f0',
            '#ff9f43',
          ],
          dataLabels: {
            enabled: true,
            formatter(val) {
              return `${parseInt(val)}%`
            },
          },
        }
      },
      list_du_an_vuong_mac:null,
      mapConfig: {
        headmap_value_key:'TONGDOANHSO',
        circle_packed_key: 'PJ_CATEGORIE_VALUE',
        tree_map_key: 'SODUAN',
        range:[]
      }
    }
  },
  watch:{
    "current_province.id": function(n,o){
      if (this.current_province.id!=''){
        var p = this.vietnam.features.filter(i=> i.id==this.current_province.id)[0]
        this.current_province.TEN_TINH = p.properties.name;
        this.current_province.AGENT_ID = p.properties.AGENT_ID;
        this.current_province.PJ_AGENT_CNT = p.properties.PJ_AGENT_CNT;
        this.current_province.PJ_AGENT_VALUE = p.properties.PJ_AGENT_VALUE;
        this.current_province.PJ_AGENT_RVN = p.properties.PJ_AGENT_RVN;
        this.current_province.show=true; 
      }     
    },
    current_date: function(n,o){
      this.setupDashboard();
    },
    "mapConfig.headmap_value_key": function(n,o){
      this.loadMap()
    },
    "mapConfig.circle_packed_key": async function(n,o){
      this.loading(true);
      this.loadPackedCircle();
      this.loading(false);
    },
    "mapConfig.tree_map_key": async function(n,o){
      this.loading(true);
      this.loadTreeMap();
      this.loading(false);
    }
  },
  mounted: function () {
    this.setupDashboard();
  },
  async created() {
  },
  methods: {
    showProjects(agent_id){
      useJwt.post('projectMgmt', {
        service:"func_get_pj_agent_vnpt_detail_service",
        type:"ref",
        p1: this.current_date.substr(3)+this.current_date.substring(0,2),
        p3: agent_id,
      }).then(response =>{
        this.list_detail.rows=response.data.result;
        this.$bvModal.show('modal-detail')
      })
    },
    async setupDashboard(){
      this.loading(true);
      //Load dữ liệu Dự án cấp tỉnh
      await useJwt.post('projectMgmt', {
        service:"func_get_pj_month_by_vnpt_center_service",
        type:"ref",
        p1: this.current_date.substr(3)+this.current_date.substring(0,2),
        p3: "1",
      }).then(response =>{
        this.du_an_cap_tinh.series=[]
        response.data.result.forEach(e=>{
          this.du_an_cap_tinh.series.push({name:e.MONTH_,data:[e.F1,e.F2,e.F3,e.F4,e.F5]})
        })
      })
      //Load dữ liệu Dự án cấp tw
      await useJwt.post('projectMgmt', {
        service:"func_get_pj_month_by_vnpt_prov_service",
        type:"ref",
        p1: this.current_date.substr(3)+this.current_date.substring(0,2),
        p3: "2",
      }).then(response =>{
        this.du_an_cap_tw.series=[]
        response.data.result.forEach(e=>{
          this.du_an_cap_tw.series.push({name:e.MONTH_,data:[e.F1,e.F2,e.F3,e.F4,e.F5]})
        })
      })
      //Load dữ liệu theo tỉ trọng
      await useJwt.post('projectMgmt', {
        service:"func_get_pj_sum_group_vnpt_service",
        type:"ref",
        p1: this.current_date.substr(3)+this.current_date.substring(0,2),
        p3: "2",
      }).then(response =>{
        //while (this.ti_trong_kenh.chartOptions.labels.length) {this.ti_trong_kenh.chartOptions.labels.pop();}
        console.log(response.data.result)
        this.ti_trong_kenh.series = [];
        response.data.result.forEach(e=>{
          this.ti_trong_kenh.series.push(e.KHONG_DOANHSO * 1);
          this.ti_trong_kenh.series.push(e.DUOI_10 * 1);
          this.ti_trong_kenh.series.push(e.TU_10_DEN_30 * 1);
          this.ti_trong_kenh.series.push(e.TU_30_DEN_50 * 1);
          this.ti_trong_kenh.series.push(e.TREN_50 * 1);
          //this.ti_trong_kenh.chartOptions.labels.push(e.TYPE_TOTAL.replace("Tỉnh có tổng ","").replace("doanh số",""))
        })
      })
      //Load dữ liệu theo quý
      await useJwt.post('projectMgmt', {
        service:"func_get_rvn_year_by_vnpt_service",
        type:"ref",
        p1: this.current_date.substr(3)+this.current_date.substring(0,2),
        p3: "2",
      }).then(response =>{
        this.du_an_theo_quy_bar.series=[];
        this.du_an_theo_quy = response.data.result;
        this.du_an_theo_quy.forEach(e=>{
          e.Q1=Math.round(e.Q1*1/1);
          e.Q2=Math.round(e.Q2*1/1);
          e.Q3=Math.round(e.Q3*1/1);
          e.Q4=Math.round(e.Q4*1/1);
          this.du_an_theo_quy_bar.series.push({name:e.NAME,data:[e.Q1,e.Q2,e.Q3,e.Q4]})
        })
      })
      //Load dữ liệu bản đồ
      await this.loadMap();
      //Load dữ liệu theo sản phẩm
      await this.loadPackedCircle();
      //Load dữ liệu theo nhóm kh
      await this.loadTreeMap();
      //Dự án khó khăn vướng mắc
      await useJwt.post('projectMgmt', {
        service:"func_get_pj_delay_vnpt_service",
        type:"ref",
        p1: this.current_date.substr(3)+this.current_date.substring(0,2),
        p3: "2",
      }).then(response =>{
        let i = 0, t = 0;
        response.data.result.forEach(e=>{
          i = i + e.SODUAN*1;
        })
        this.provinces.forEach(e=>{
          t = t + e.PJ_AGENT_CNT*1;
        })
        this.du_an_vuong_mac.chartOptions.labels.pop();
        this.du_an_vuong_mac.chartOptions.labels.pop();
        this.du_an_vuong_mac.chartOptions.labels.push('Vướng mắc')
        this.du_an_vuong_mac.chartOptions.labels.push('Tổng dự án')
        this.du_an_vuong_mac.series = [i,t];
      })
      //Dự án khó khăn vướng mắc
      await useJwt.post('projectMgmt', {
        service:"func_get_pj_have_issue_by_boss_service",
        type:"ref",
        p1: this.current_date.substr(3)+this.current_date.substring(0,2),
        p3: "2",
      }).then(response =>{
        this.list_du_an_vuong_mac = response.data.result;
      })
      this.loading(false)
    },
    async loadTreeMap(){
      await useJwt.post('projectMgmt', {
        service:"func_get_pj_customer_by_vnpt_service",
        type:"ref",
        p1: this.current_date.substr(3)+this.current_date.substring(0,2),
        p3: "2",
      }).then(response =>{
        this.du_an_theo_nhom_kh=[]
        this.du_an_theo_sp=[]
        var _mapConfig = this.mapConfig;
        response.data.result.forEach(e=>{
          this.du_an_theo_nhom_kh.push({CATEG_CODE: e.CATEG_CODE, name:e.NHOMKHACHHANG,value:e[_mapConfig.tree_map_key]}) 
          var chart = am4core.create("da_theo_kh", am4charts.TreeMap);
          chart.hiddenState.properties.opacity = 0; // this makes initial fade in effect

          chart.data = this.du_an_theo_nhom_kh;
          //console.log(this.du_an_theo_nhom_kh)

          // define data fields
          chart.dataFields.value = "value";
          chart.dataFields.name = "name";
          chart.dataFields.children = "children";

          var level1 = chart.seriesTemplates.create("0");
          var level1_bullet = level1.bullets.push(new am4charts.LabelBullet());
          level1_bullet.locationY = 0.5;
          level1_bullet.locationX = 0.5;
          level1_bullet.label.text = "{name}";
          level1_bullet.label.fill = am4core.color("#fff");

          level1.columns.template.events.on('hit', function(ev) {
            useJwt.post('projectMgmt', {
              service:"func_get_list_pj_customer_by_vnpt_service",
              type:"ref",
              p1: this.current_date.substr(3)+this.current_date.substring(0,2),
              p3: ev.target.dataItem.dataContext.dataContext.CATEG_CODE,
            }).then(response =>{
              //console.log(response.data.result)
              this.list_detail.rows=response.data.result;
              this.$bvModal.show('modal-detail')
            })
          }, this);

          chart.paddingTop = 0;
          chart.paddingBottom = 0;
          chart.paddingLeft = 0;
          chart.paddingRight = 0;

          chart.strokeWidth=0
        })
      })
    },
    async loadPackedCircle(){
      await useJwt.post('projectMgmt', {
        service:"func_get_pj_product_by_vnpt_service",
        type:"ref",
        p1: this.current_date.substr(3)+this.current_date.substring(0,2),
        p3: "2",
      }).then(response =>{
        this.du_an_theo_sp=[]
        var _mapConfig = this.mapConfig;
        //console.log(response.data.result)
        response.data.result.forEach(e=>{
          this.du_an_theo_sp.push({PRODUCT_PARENT_ID: e.PRODUCT_PARENT_ID,name:e.PJ_PRODUCT_NAME,value:e[_mapConfig.circle_packed_key]})  
          
          var chart = am4core.create("da_theo_spdv", am4plugins_forceDirected.ForceDirectedTree);
          var networkSeries = chart.series.push(new am4plugins_forceDirected.ForceDirectedSeries())

          networkSeries.data = this.du_an_theo_sp;

          networkSeries.dataFields.linkWith = "linkWith";
          networkSeries.dataFields.name = "name";
          networkSeries.dataFields.id = "name";
          networkSeries.dataFields.value = "value";
          networkSeries.links.template.distance = 1;
          networkSeries.nodes.template.tooltipText = "{name} - {value}";
          networkSeries.nodes.template.fillOpacity = 1;

          networkSeries.nodes.template.events.on("hit", function(ev) {
            useJwt.post('projectMgmt', {
              service:"func_get_list_pj_product_by_vnpt_service",
              type:"ref",
              p1: this.current_date.substr(3)+this.current_date.substring(0,2),
              p3: ev.target.dataItem.dataContext.PRODUCT_PARENT_ID,
            }).then(response =>{
              this.list_detail.rows=response.data.result;
              this.$bvModal.show('modal-detail')
            })
          }, this);

          networkSeries.nodes.template.label.text = "{name}"
          networkSeries.fontSize = 8;
          networkSeries.nodes.template.label.truncate = true;
          networkSeries.minRadius = am4core.percent(4);
          networkSeries.manyBodyStrength = -5;
          networkSeries.links.template.strokeOpacity = 0;
        })
      })

    },
    async loadMap(){
      var _mapConfig = this.mapConfig;
      await useJwt.post('projectMgmt', {
        service:"func_get_pj_by_province_service",
        type:"ref",
        p1: this.current_date.substr(3)+this.current_date.substring(0,2),
      }).then(response =>{
        var p = response.data.result.sort(function(a,b){return a[_mapConfig.headmap_value_key] * 1 - b[_mapConfig.headmap_value_key] *1 })
        var i = 0;
        let _range=[];
        p.forEach(e=>{
          var polygon = this.vietnam.features.filter(i=> i.properties.name==e.PROVINCE_NAME)[0];
          //console.log(e)
          let percent = e.TONGDOANHSO==0?100:Math.round(e.TONGDOANHTHU * 100/e.TONGDOANHSO)
          this.provinces.push({id: polygon.id, text: e.PROVINCE_NAME, AGENT_ID:e.PARENT_ID, PJ_AGENT_CNT: e.TONGDUAN, PJ_AGENT_VALUE: this.nf(e.TONGDOANHTHU)+'/'+this.nf(e.TONGDOANHSO)+' tỉ', PJ_AGENT_RVN: e.TONGDOANHSO, P: percent})
          var color = '#ea5455'; //#28c76f //#ff9f43 //#ea5455 //#7367f0
          if (i>12) color = '#7367f0';
          if (i>25) color = '#ff9f43';
          if (i>37) color = '#00cfe8';
          if (i>50) color = '#28c76f';
          if (_range.filter(i => i.color === color).length==0 && i>12){
            _range.push({color:color,text: ' >= '+e[[_mapConfig.headmap_value_key]]})
          }else if (i==12){
            _range.push({color:color,text: ' <= '+e[[_mapConfig.headmap_value_key]]})
          }
          polygon.properties.fill=color;
          polygon.properties.PJ_AGENT_CNT=e.TONGDUAN;
          polygon.properties.PJ_AGENT_VALUE=e.TONGDOANHTHU;
          polygon.properties.PJ_AGENT_RVN=e.TONGDOANHSO;
          polygon.properties.AGENT_ID=e.PARENT_ID;
          i++;
        })
        this.mapConfig.range = _range;
        let map = am4core.create("vnmap", am4maps.MapChart)
        map.geodata = this.vietnam
        map.projection = new am4maps.projections.Miller()
        var polygonSeries = map.series.push(new am4maps.MapPolygonSeries())
        polygonSeries.useGeodata = true
        map.homeZoomLevel = 1.40
        map.homeGeoPoint = {
          latitude: 16.0583,
          longitude: 107.2772
        };
        // Configure series
        var polygonTemplate = polygonSeries.mapPolygons.template;
        polygonTemplate.tooltipHTML = 
            `<div class="card p-0 m-0 border-light" style="min-width:200px">
              <div class="card-head" style="background:{fill}">
                <span class="title">{name}</span>
              </div>
              <div class="card-body p-0">
                <div class="p-1 rounded" style="background:#2D345F">
                  <div class="row"><div class="col-7">Số dự án</div><div class="col-5">{PJ_AGENT_CNT}</div></div>
                  <div class="row"><div class="col-7">Doanh thu</div><div class="col-5">{PJ_AGENT_VALUE} tỉ</div></div>
                  <div class="row"><div class="col-7">Doanh số</div><div class="col-5">{PJ_AGENT_RVN} tỉ</div></div>
                </div>
              </div>`;
        polygonSeries.calculateVisualCenter = true;
        polygonTemplate.propertyFields.fill = "fill";
        polygonTemplate.fillOpacity = 0.7
        //polygonTemplate.fill = am4core.color("{color}");
        var hs = polygonTemplate.states.create("hover");
        hs.properties.fill = am4core.color("#2D345F");

        polygonTemplate.tooltipPosition = "fixed";
        polygonSeries.tooltip.fill='none';
        polygonSeries.tooltip.strokeWidth=0
        polygonSeries.tooltip.getFillFromObject = false;
        polygonSeries.tooltip.background.fill = am4core.color("rgba(0,0,0,0)");
        polygonSeries.tooltip.keepTargetHover = true;
        polygonSeries.tooltip.background.strokeWidth = 0;
      })
    },
    nf: function(value) {
      return value.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")
    }
  },
  beforeDestroy() {
    am4core.disposeAllCharts()
  },
};
</script>
<style>
.dark-layout .modal-backdrop {
   background-color: rgba(255,255,255,0.6);
}
.dashboard .period-box{
  height: 110px;
  background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAzIiBoZWlnaHQ9IjY2IiB2aWV3Qm94PSIwIDAgMjAzIDY2IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8ZyBvcGFjaXR5PSIwLjIiPgo8cmVjdCB5PSI0NS4zNzM5IiB3aWR0aD0iMTQuNzg0MyIgaGVpZ2h0PSIyMC42MjQ2IiBmaWxsPSIjNUE1RDgzIi8+CjxyZWN0IHg9IjE2LjkxODUiIHk9IjMwLjkzNjQiIHdpZHRoPSIxNS4zMTIzIiBoZWlnaHQ9IjM1LjA2MTkiIGZpbGw9IiM1QTVEODMiLz4KPHJlY3QgeD0iMzQuMzY0NyIgeT0iNDEuMjQ4OCIgd2lkdGg9IjE0Ljc4NDMiIGhlaWdodD0iMjQuNzQ5NiIgZmlsbD0iIzVBNUQ4MyIvPgo8cmVjdCB4PSI1MS4yODQyIiB5PSIyNC43NDk5IiB3aWR0aD0iMTQuNzg0MyIgaGVpZ2h0PSI0MS4yNDkzIiBmaWxsPSIjNUE1RDgzIi8+CjxyZWN0IHg9IjY4LjIwMjYiIHk9IjQxLjI0ODgiIHdpZHRoPSIxNS4zMTIzIiBoZWlnaHQ9IjI0Ljc0OTYiIGZpbGw9IiM1QTVEODMiLz4KPHJlY3QgeD0iODUuNjQ4OSIgeT0iNi4xODg2IiB3aWR0aD0iMTQuNzg0MyIgaGVpZ2h0PSI1OS44MTE0IiBmaWxsPSIjNUE1RDgzIi8+CjxyZWN0IHg9IjEwMi41NjciIHk9IjIyLjY4NjMiIHdpZHRoPSIxNC43ODQzIiBoZWlnaHQ9IjQzLjMxMTciIGZpbGw9IiM1QTVEODMiLz4KPHJlY3QgeD0iMTE5LjQ4NiIgeT0iNDEuMjQ4OCIgd2lkdGg9IjE1LjMxMjMiIGhlaWdodD0iMjQuNzQ5NiIgZmlsbD0iIzVBNUQ4MyIvPgo8cmVjdCB4PSIxMzYuOTMyIiB5PSIyMi42ODYzIiB3aWR0aD0iMTQuNzg0MyIgaGVpZ2h0PSI0My4zMTE3IiBmaWxsPSIjNUE1RDgzIi8+CjxyZWN0IHg9IjE1My44NTIiIHk9IjEwLjMxMzciIHdpZHRoPSIxNC43ODQzIiBoZWlnaHQ9IjU1LjY4NjUiIGZpbGw9IiM1QTVEODMiLz4KPHJlY3QgeD0iMTcwLjc3IiB3aWR0aD0iMTUuMzEyMyIgaGVpZ2h0PSI2NS45OTg4IiBmaWxsPSIjNUE1RDgzIi8+CjxyZWN0IHg9IjE4OC4yMTUiIHk9IjM1LjA2MjQiIHdpZHRoPSIxNC43ODQzIiBoZWlnaHQ9IjMwLjkzNyIgZmlsbD0iIzVBNUQ4MyIvPgo8L2c+Cjwvc3ZnPgo=') right bottom / 50% 34% no-repeat;
}
.dashboard .card {
    border: none;
    margin-bottom: 0.8rem;
}
.dashboard .row {
    margin-right: -0.4rem;
    margin-left: -0.4rem;
    margin-bottom: 0rem;
}
.col-xl, .col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg, .col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md, .col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm, .col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col, .col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
    padding-right: 0.4rem;
    padding-left: 0.4rem;
}
.dark-layout .dashboard .card-head {
    background: #2D345F;
    font-size: 16px;
    padding: 0px 6px 0px 15px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    font-weight: 600;
    height: 37px;
    line-height: 37px;
    overflow: hidden;
    border-bottom:none;
}
.dashboard .card-head {
    font-size: 16px;
    padding: 0px 15px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    font-weight: 600;
    height: 37px;
    line-height: 37px;
    overflow: hidden;
    border-bottom: 1px solid #dae1e7
}
.dashboard .card-head .select-box {
    float: right;
    margin-top: 0px; 
}
.dashboard .card-head .select-box .select2-container {
  width: 200px !important; 
}
.dashboard .card-head .select-box.fullw .select2-container {
  width: 100% !important; 
}
.dashboard .card-head.onlySelect .select-box {
  width: 100%; 
}
.dashboard .flat-pickr:before {
  content: "";
  position: absolute;
  left: 12px;
  top: 0;
  bottom: 0;
  width: 12px;
  background: url("data:image/svg+xml;base64,PHN2ZyBkYXRhLXYtNGMwMzRmZTY9IiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB3aWR0aD0iMjRweCIgaGVpZ2h0PSIyNHB4IiB2aWV3Qm94PSIwIDAgMjQgMjQiIGZpbGw9Im5vbmUiIHN0cm9rZT0iY3VycmVudENvbG9yIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgY2xhc3M9Imljb24tc3ZnIGZlYXRoZXIgZmVhdGhlci1jYWxlbmRhciI+PHJlY3QgZGF0YS12LTRjMDM0ZmU2PSIiIHg9IjMiIHk9IjQiIHdpZHRoPSIxOCIgaGVpZ2h0PSIxOCIgcng9IjIiIHJ5PSIyIj48L3JlY3Q+PGxpbmUgZGF0YS12LTRjMDM0ZmU2PSIiIHgxPSIxNiIgeTE9IjIiIHgyPSIxNiIgeTI9IjYiPjwvbGluZT48bGluZSBkYXRhLXYtNGMwMzRmZTY9IiIgeDE9IjgiIHkxPSIyIiB4Mj0iOCIgeTI9IjYiPjwvbGluZT48bGluZSBkYXRhLXYtNGMwMzRmZTY9IiIgeDE9IjMiIHkxPSIxMCIgeDI9IjIxIiB5Mj0iMTAiPjwvbGluZT48L3N2Zz4=") center / contain no-repeat;
}
.dashboard .select2-selection--single:before {
  content: "";
  position: absolute;
  right: 12px;
  top: 0;
  bottom: 0;
  width: 12px;
  background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iNiIgdmlld0JveD0iMCAwIDEwIDYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xIDFMNSA1TDkgMSIgc3Ryb2tlPSIjMDBCMEVDIiBzdHJva2Utd2lkdGg9IjEuMyIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPg==") center / contain no-repeat;
}
.dark-layout .dashboard .select2-selection--single{
  background: #464E79 !important;
}
.dashboard .select2-selection--single{
  height: 24px !important;
  background: #f6f6f6 !important;
  min-height: 24px !important;
  font-size: 12px !important;
  border: none !important;
  margin: 0px !important;
  padding: 0 !important;
}
.dashboard .select2-selection__arrow{
  background: none;
  display: none !important;
}
.dashboard .select2-selection__rendered{
  line-height: 24px !important;
}
.dark-layout .dashboard .select2-selection__rendered{
  color: #fff !important;
}
.dashboard .vgt-table td,.vgt-table th{
  padding: 0.25em 0.35em !important;
  vertical-align: middle !important;
  font-size: 0.9rem;
}
.dashboard .vgt-table th{
  text-align: center;
}
</style>
<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
</style>
